@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background: #ebebeb;
  font-family: 'Montserrat', sans-serif;
}

.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl('padding-right', var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.sidebar {
  font-size: 14px;
  background: #042849;
  .sidebar-header {
    padding: 20px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    .profileDetails {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #ffffff;
      .profileIconBox {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        font-weight: bold;
        line-height: 40px;
        border: 1px solid #f4f4f4;
      }
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
      }
      p {
        margin: 0;
        color: #f4f5fa;
      }
    }
    .sidebar-toggler {
      font-size: 24px;
      box-shadow: none;
      width: 14px;
      height: 14px;
      &::before {
        font-size: 24px;
        background-color: rgba(1, 144, 64, 1);
      }
    }
  }
  .simplebar-content {
    padding: 0 !important;
  }

  .sidebar-nav {
    .nav-link {
      border-radius: 0;
      color: #f4f4f4;
      svg.nav-icon {
        color: #f4f4f4;
      }
      .nav-icon-bullet {
        background: #fff;
        width: 8px;
        height: 8px;
      }
      &:hover {
        color: #000;
        svg.nav-icon {
          color: #000;
        }
      }
    }
    .nav-link.active {
      color: #000;
      svg.nav-icon {
        color: #000;
      }
    }
    .nav-group-toggle {
      &:hover {
        color: #000;
      }
      &::after {
        background: #fff;
      }
    }
  }
}

.sidebar-nav {
    .nav-group.show {
        .nav-group-toggle{
            color: #f4f4f4;
            &:hover {
                color: #000;
              }
        }
    }
}

.sidebar.sidebar-narrow-unfoldable {
  .profileTextBox {
    display: none;
  }
  .sidebar-toggler {
    display: none;
  }
  svg.nav-icon {
    flex: 0 0 45px;
    height: 26px;
  }
  &:hover {
    .profileTextBox {
      display: block;
    }
    .sidebar-toggler {
      display: block;
    }
    svg.nav-icon {
      flex: 0 0 20px;
      height: auto;
    }
  }
}

.header {
  .header-toggler {
    .icon {
      color: #042849;
      width: 24px;
      height: 24px;
    }
  }
}

.footer {
  background: rgb(218, 218, 218);
}


.loginPage {
  background: url(../assets/images/loginBG.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  .loginFrom {
    background: #ffffff;
    border-radius: 10px;
    form{
      padding: 20px 15px;
      .login_primary {
        background: rgb(47 94 124);
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}